const prod = {
    url: {
        API_ENDPOINT: "https://api.thedivash.com", // use in production
        IMG_ENDPOINT: "https://media-assets.thedivash.com",
        PG_ENDPOINT: "https://api.phonepe.com/apis/hermes/pg/v1/pay"
    }
};
const uat = {
    url: {
        API_ENDPOINT: "https://uat.api.thedivash.com", // use in production
        IMG_ENDPOINT: "https://uat.media-assets.thedivash.com",
        PG_ENDPOINT: "https://uat.api.phonepe.com/apis/hermes/pg/v1/pay"
    }
}
const dev = {
    url: {
        // API_ENDPOINT: "http://192.168.1.73:8000", // use in developement
        API_ENDPOINT: "http://127.0.0.1:8000", 
        IMG_ENDPOINT: "https://media-assets.thedivash.com",
        PG_ENDPOINT: "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
    }
};

let configUsed = prod;
if(process.env.NODE_ENV === 'development'){
    configUsed = dev;
}
if(window.location.origin.toLowerCase().indexOf('uat') > -1){
    configUsed = uat;
}
// export const config = configUsed;
export const config = configUsed;