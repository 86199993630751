import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Paper } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";

import { DivashLogoIcon } from './commons/icons';
import UserProfile from './User';

const pages = [];

function PaperWrapper(props) {
    const { children, theme, changeTheme, } = props;
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (setting) => {
        setAnchorElUser(null);
        if (setting === "Theme") {
            changeTheme(theme === "light" ? "dark" : "light");
        }
        if (setting === "Logout") {
            UserProfile.logout()
        }
    };

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* Below not shown on phone */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            onClick={(e) => {
                                navigate("/")
                            }}
                        >
                            {/* <DivashLogoIcon 
                                fontSize="large"
                                color="success"
                            /> */}
                            DIVASH
                        </Typography>
                        {/* Below is only shown on phone */}
                        <Box sx={{ flexGrow: 0.8, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="medium"
                                color="inherit"
                                onClick={(e) => {
                                    navigate("/")
                                }}
                            >
                                DIVASH
                            </IconButton>
                        </Box>
                        {/* Below is not shown on phone */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar
                                        alt={UserProfile.isLoggedIn() ? UserProfile.getName().toUpperCase() : ""}
                                        src="/static/images/avatar/2.jpg"
                                    />

                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={"Theme"} onClick={(e) => handleCloseUserMenu("Theme")}>
                                    <Typography sx={{ textAlign: 'center' }}>Change to {theme === "light" ? "Dark" : "Light"} Theme</Typography>
                                </MenuItem>
                                {UserProfile.isLoggedIn() ?
                                    <MenuItem key={"Logout"} onClick={(e) => handleCloseUserMenu("Logout")}>
                                        <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
                                    </MenuItem> : <></>
                                }

                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Paper>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    // alignItems="center"
                    // justifyContent="center"
                    sx={{ minHeight: {xs:'85vh', md:'100vh' }}}
                >
                    <Grid item xs={3}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )

}

export default PaperWrapper;