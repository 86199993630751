import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useCookies } from 'react-cookie';

import { useLocation } from 'react-router-dom'


import './App.css';
import LoginPage from './components/login'
import HomePage from "./components/homepage";
import { AddInventory, ViewInventory, ViewInventoryDetails, AddPreparation, AddWastage } from "./components/inventory";
import { AddBill, ViewBillsList, ViewBillDetails } from './components/Bills';
import PaperWrapper from "./components/page_wrapper";
import { ViewOrders } from './components/Orders';



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light'
  },
});


function App() {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success")
  const [snackBarMessage, setSnackBarMessage] = useState("")
  const [cookies, setCookies, removeCookies] = useCookies(['divash-admin-theme']);

  if (cookies['divash-admin-theme'] === null) {
    setCookies('divash-admin-theme', "light");
  }

  const showErrorMessage = (message) => {
    setSnackBarMessage(message);
    setAlertSeverity("error");
    setShowSnackBar(true);
  }

  const showSuccessMessage = (message) => {
    setSnackBarMessage(message);
    setAlertSeverity("success");
    setShowSnackBar(true);
  }

  return (
    <ThemeProvider theme={cookies['divash-admin-theme'] === 'light' ? lightTheme : darkTheme} >
      <CssBaseline />
      <BrowserRouter>
        <PaperWrapper theme={cookies['divash-admin-theme']} changeTheme={(theme) => {
          setCookies('divash-admin-theme', theme)
        }}>
          <Snackbar
            open={showSnackBar}
            autoHideDuration={30000}
            onClose={(e) => {
              setShowSnackBar(false);
            }}
          >
            <Alert
              onClose={(e) => {
                setShowSnackBar(false);
              }}
              variant="filled"
              severity={alertSeverity}
            >
              {snackBarMessage}
            </Alert>
          </Snackbar>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>

            <Routes>
              <Route path="/" element={<LoginPage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route index element={<LoginPage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/home" element={<HomePage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />

              <Route path="/inventory/add/status" element={<AddInventory showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/inventory/add/preparation" element={<AddPreparation showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/inventory/view" element={<ViewInventory showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/inventory/view/details/*" element={<ViewInventoryDetails showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />

              <Route path="/inventory/add/wastage/" element={<AddWastage route="wastage" showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/inventory/add/usage/" element={<AddWastage route="usage" showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />

              <Route path="/bill/add" element={<AddBill showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/bill/view" element={<ViewBillsList showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />
              <Route path="/bill/view/*" element={<ViewBillDetails showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />

              <Route path="/orders/view" element={<ViewOrders showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />} />

              <Route
                path="*"
                element={
                  <>TODO</>
                }
              />

            </Routes>
          </CookiesProvider>
        </PaperWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
