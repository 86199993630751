import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";

import api from '../../utils/webcall';
import { Button } from '@mui/material';
import UserProfile from '../User';
import AccessController from '../access_controller';

function InventorViewListRow(props) {
    const { data, index } = props;
    const navigate = useNavigate();
    return (
        <TableRow
            key={`inventory-form-view-row-${index}`}
        >
            <TableCell align="center">{data['item_name']}</TableCell>
            <TableCell align="center">{data['current_inventory']} {data['measurement_unit']}</TableCell>
            <TableCell align="center">{data['last_inventory_date']}</TableCell>
            <TableCell align="center">TODO</TableCell>
            <TableCell align="center">
                <Button
                    onClick={(e) => {
                        navigate(`/inventory/view/details/${data['item_name']}`)
                    }}
                >
                    ...
                </Button>
            </TableCell>
        </TableRow>
    )
}

function InventoryViewList(props) {
    const { data } = props;
    if (data === null || data === undefined) {
        return <>Inventory List in empty</>;
    }
    return (
        <TableContainer
            component={Paper}
            style={{
                overflowX: "initial"
            }}
            key="inventory-table-container"
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Item Name</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Update time</TableCell>
                        <TableCell align="center">Updated by User</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        return (
                            <InventorViewListRow
                                data={item}
                                index={index}
                            />
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ViewInventory(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [loading, setLoading] = useState(true);
    const [inventoryData, setInventoryData] = useState(null);
    const username = UserProfile.getName();

    const loadInventoryStatus = () => {
        setLoading(true);
        api.get(`/pos/inventory/view/`).then((res) => {
            if (res.status === 200) {
                let data = res.data['inventory_items'];
                setInventoryData(data);
                setLoading(false);
            }
            else{
                showErrorMessage("Unhandled. Contact Developer")
            }
        }).catch((error) => {
            console.log(error);
            showErrorMessage(String(error));
        });
    }

    useEffect(() => {
        loadInventoryStatus();
    }, []);

    return (
        <AccessController>
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <h2>DIVASH | ADMIN</h2>
                <Grid item>
                    <i>Welcome {username}</i>
                </Grid>

            </Grid>
            {loading ? <Grid item><CircularProgress /></Grid> : <></>}
            {!loading ? <InventoryViewList data={inventoryData} /> : <></>}
        </AccessController>
    )
}

export default ViewInventory;