import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UserProfile from "./User";


function AccessController(props) {
    const { restricted, onlyAdmin, children } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (restricted) {
            if (!UserProfile.isLoggedIn()) {
                navigate("/");
            }
        }
        if (onlyAdmin) {
            if (!UserProfile.isManager()) {
                navigate("/");
            }
        }
    }, [restricted, onlyAdmin])


    return (
        <>
            {children}
        </>
    )
}



AccessController.defaultProps = {
    restricted: true,
    onlyAdmin: true
};

export default AccessController;