import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";


import api from '../../utils/webcall';
import Checkbox from '@mui/material/Checkbox';
import UserProfile from '../User';
import AccessController from '../access_controller';
import { transformUnits } from '../../utils/utils';


function PreparionDataTable(props) {
    const { data, update, options } = props;
    if (data.length == 0) {
        return <></>
    }
    return (<>
        {data.map((item, index) => {
            return (
                <TableRow
                    key={`bill-data-row-${index}`}
                >
                    <TableCell align="center">
                        <Autocomplete
                            id={`outlined-adornment-bill-row-${index}`}
                            // freeSolo
                            fullWidth={true}
                            autoSelect
                            sx={{minWidth:'200px'}}
                            onChange={(e) => {
                                update(index, e.target.innerText, 'item_name');
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        update(index, e.target.value, 'item_name');
                                    }}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            type: 'search',
                                        },
                                    }}
                                />
                            )}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <OutlinedInput
                            id={`outlined-adornment-bill-row-${index}`}
                            value={item['quantity']}
                            onChange={(e) => {
                                update(index, e.target.value, 'quantity');
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <Select
                            key={`bill-row-${index}-`}
                            value={item['measurement_unit']}
                            label="Age"
                            onChange={(e) => {
                                update(index, e.target.value, 'measurement_unit');
                            }}
                        >
                            <MenuItem value={"Grams"}>Grams</MenuItem>
                            <MenuItem value={"Kilograms"}>Kilograms</MenuItem>
                            <MenuItem value={"Milliliters"}>Milliliters</MenuItem>
                            <MenuItem value={"Liters"}>Liters</MenuItem>
                            <MenuItem value={"Centimetres"}>Centimeters</MenuItem>
                            <MenuItem value={"Meters"}>Meters</MenuItem>
                            <MenuItem value={"Pieces"}>Pieces</MenuItem>
                        </Select>
                    </TableCell>
                    {/* <TableCell align="center">
                        <OutlinedInput
                            id={`outlined-adornment-bill-row-${index}`}
                            value={item['total_price']}
                            onChange={(e) => {
                                update(index, e.target.value, 'preparation_time');
                            }}
                        />
                    </TableCell> */}
                    <TableCell align="center">
                        <Checkbox
                            checked={item['already_added_in_inventory']}
                            onChange={(e) => {
                                console.log(e.target.checked);
                                update(index, e.target.checked, 'already_added_in_inventory');
                            }}
                        />
                    </TableCell>
                </TableRow>
            )
        })}
    </>)
}


function AddPreparation(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [inventoryItemsList, setInventoryItemsList] = useState([])
    const [preparationData, setPreparationData] = useState([]);
    const navigate = useNavigate();
    const username = UserProfile.getName();

    const update = (index, value, target) => {
        let temp = preparationData;
        temp[index][target] = value;
        setPreparationData([...temp]);
    }

    useEffect(() => {
        api.get(`/pos/inventory/list/in_house_preps/`).then((res) => {
            if (res.status === 200) {
                let temp = res.data;
                setInventoryItemsList(temp);
            }
            else {
                console.log(res);
                showErrorMessage(res);
            }
        }).catch(error => {
            console.log(error);
            showErrorMessage(String(error));
        })
    }, []);

    const handleSubmit = () => {
        let temp = preparationData;
        let i;
        for (i = 0; i < temp.length; i++) {
            let temp2 = transformUnits(temp[i]['quantity'], temp[i]['measurement_unit'])
            console.log(temp2);
            temp[i]['measurement_unit'] = temp2[1];
            temp[i]['quantity'] = temp2[0];
        }
        let data = {
            'payload': temp,
        }
        console.log(temp);
        api.post(`/pos/inventory/update/in_house_preps/`, data).then((res) => {
            if(res.status === 200){
                showSuccessMessage("Inventory Updated");
                navigate("/inventory/view")
            }
        }).catch((error) => {
            console.log(error);
            if('status' in error && error['status'] === 400){
                console.log('here')
                console.error(error['response']['data'])
                showErrorMessage(error['response']['data']);
            }
            else{
                showErrorMessage(String(error));
            }
            
        })

    }

    const addNewRow = () => {
        let temp = preparationData;
        temp.push({
            'item_name': '',
            'quantity': '',
            'measurement_unit': 'Grams',
            'preparation_time': '',
            'already_added_in_inventory': false
        })
        setPreparationData([...temp]);
    }

    return (
        <AccessController
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <h2>DIVASH | ADMIN</h2>
                <Grid item sx={{
                    textAlign: 'center'
                }}>
                    <i>Welcome {username}</i>. <br />
                    <b>Submit New Items Prepared today</b>
                </Grid>
                <Grid item>
                    <TableContainer
                        component={Paper}
                        style={{
                            overflowX: "initial"
                        }}
                        key="inventory-table-container"
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Measurement Unit</TableCell>
                                    {/* <TableCell align="center">Preparation Time</TableCell> */}
                                    <TableCell align="center">Was already included in Inventory</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <PreparionDataTable data={preparationData} update={update} options={inventoryItemsList} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            addNewRow()
                        }}
                        sx={{
                            margin: '10px'
                        }}
                    >
                        Add New Row
                    </Button>
                </Grid>

                <Grid item>

                    <Button
                        variant="outlined"
                        sx={{
                            margin: '10px'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit()
                        }}
                        disabled={preparationData.length === 0}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </AccessController >
    )
}

export default AddPreparation;