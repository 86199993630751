import { styled } from '@mui/material/styles';

export const filterItems = (items, query, categoryMapping) => {
    // Trim the query to remove leading and trailing spaces
    const trimmedQuery = query.trim().toLowerCase();

    return items.filter(item => {
        // Convert item fields to lowercase for case-insensitive comparison
        const itemName = item.item_name.toLowerCase();
        const itemDescription = item.description.toLowerCase();
        const categoryTitle = (categoryMapping[item.item_category] || "").toLowerCase();

        // Check if any of the item fields include the trimmed query
        return (
            itemName.includes(trimmedQuery) ||
            itemDescription.includes(trimmedQuery) ||
            categoryTitle.includes(trimmedQuery)
        );
    });
};

export const transformUnits = (quantity, measuring_unit) =>{
    if (measuring_unit === 'Kilograms') {
        quantity *= 1000;
        measuring_unit = 'Grams';
    }
    else if (measuring_unit === 'Liters') {
        quantity *= 1000;
        measuring_unit = 'Milliliters';
    }
    else if (measuring_unit === 'Meters') {
        quantity *= 100;
        measuring_unit = 'Centimeters';
    }
    return [quantity, measuring_unit];
}

export const formatDate = (dt) => {
    const yyyy = dt.getFullYear();
    let mm = dt.getMonth() + 1; // Months start at 0!
    let dd = dt.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '/' + mm + '/' + dd;
    return formattedToday;
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});